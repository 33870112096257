import HeroLayout1 from "components/common/hero/HeroLayout1";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import AccessoriesContainer, {
  MOBILE_MEDIA_QUERY,
} from "./AccessoriesContainer";
import { DEFAULT_SELECTIONS } from "./constants";
import useIsMobile from "hooks/useIsMobile";

export default function AccessoriesV2({
  defaultSelection = DEFAULT_SELECTIONS.cameras,
}) {
  const { isMobile } = useIsMobile(MOBILE_MEDIA_QUERY);

  const renderContent = data => {
    return (
      <>
        <HeroLayout1
          data={{
            preTitle: "Hardware Accessories",
            title:
              "Enhance your Security Infrastructure with Rhombus Accessories ",
            description:
              !isMobile &&
              "Select a model on the left to view compatible accessories. ",
            image: data.heroImage,
          }}
          bgColor={"var(--gradient-light-fade)"}
          minHeight="unset"
        />
        <AccessoriesContainer defaultSelection={defaultSelection} />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "components/accessories/v2/img/r600-parking-lot-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
